@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700&display=swap');

@primary-color: #e5b002;
@secondary-color: #fe7400;
@background-color: #fefdfa;
@text-color: #606266;
@secondary-text-color: #c0c4cc;
@title-color: #303133;
@input-border-color: #dcdfe6;

@base-margin: 16px;
@primary-font-size: 14px;
@secondary-font-size: 12px;
@large-item-height: 40px;
@header-height: 72px;
@common-radius: 4px;
@common-box-shadow: 0 2px 4px 0 @input-border-color;
@common-animation: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

#root {
  height: 100vh;
  white-space: pre-line;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto;
  background-color: @background-color;
}

/* AMPLIFY */

[data-amplify-authenticator] {
  height: 100vh;
}

/* ANT DESIGN */

.ant-layout {
  min-height: 100vh;
  background-color: @background-color;
}

.ant-layout-header {
  display: flex;
  justify-content: center;
  height: @header-height;
  line-height: @header-height;
  padding-left: @base-margin;
  padding-right: @base-margin;
  color: @text-color;
  background: white;
  box-shadow: @common-box-shadow;
}

.ant-layout-footer {
  padding: 0;
  color: @text-color;
  background: white;
  box-shadow: 0 0px 4px 0 @input-border-color;
  text-align: center;
}

.ant-menu {
  text-transform: uppercase;
  font-size: 13px;
  justify-content: flex-end;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-menu-vertical {
  border-right: none;
}

.ant-menu-item {
  text-align: center;
}

.ant-breadcrumb {
  padding-left: 24px;
}

.ant-breadcrumb-link {
  cursor: pointer;
  line-height: @header-height;
  font-size: 20px;
}

.ant-card {
  box-shadow: @common-box-shadow;
  border-radius: @common-radius;
  margin-bottom: @header-height;
}

.ant-card-body {
  padding: @base-margin;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.ant-drawer-close {
  font-size: calc(@header-height / 2);
  margin-top: calc(@header-height / 4);
  cursor: pointer;
}

.ant-drawer-content-wrapper {
  height: 100vh !important;
}

.ant-table {
  box-shadow: @common-box-shadow;
}

.ant-table {
  border: solid 1px @input-border-color;
  border-radius: @common-radius;
}

.ant-table-content {
  .ant-table-thead {
    th {
      color: @title-color;
    }
  }
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  overflow-wrap: anywhere;
}

.ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle .ant-table-thead > tr > th, .ant-table.ant-table-middle .ant-table-tbody > tr > td, .ant-table.ant-table-middle tfoot > tr > th, .ant-table.ant-table-middle tfoot > tr > td {
  padding: calc(@base-margin / 2) @base-margin;

  @media (max-width: 1024px) {
    padding: calc(@base-margin / 4) calc(@base-margin / 2);
    font-size: @secondary-font-size;
  }
}

.ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next {
  height: 38px;
  line-height: 38px;
}

.ant-pagination.mini .ant-pagination-item {
  height: 38px;
  line-height: 38px;
}

.ant-pagination.mini .ant-pagination-jump-prev, .ant-pagination.mini .ant-pagination-jump-next {
  height: 38px;
  line-height: 38px;
}

.ant-btn {
  font-size: @primary-font-size;
  border-radius: @common-radius !important;
}

.ant-btn-lg {
  height: @large-item-height;
}

.ant-input-affix-wrapper {
  padding-left: 10px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.ant-input-prefix {
  color: @secondary-text-color;
  font-size: @primary-font-size;
  margin-right: 10px;
}

.ant-input-suffix {
  color: @secondary-text-color;
  font-size: @primary-font-size;
  margin-left: 10px;
  margin-right: 10px;
}

.ant-select-selector {
  height: @large-item-height !important;
  border-color: @input-border-color !important;
  align-items: center;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-form-item-control {
  .ant-input {
    height: @large-item-height;
  }

  .ant-input-textarea-show-count > .ant-input {
    height: 100%;
  }
}

.ant-form-item-label {
  padding-bottom: calc(@base-margin / 4) !important;
  font-weight: bold;
  color: @title-color;

  label {
    font-size: @secondary-font-size;
  }
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: calc(@base-margin / 4);
  color: @title-color;
  font-size: @secondary-font-size;
  content: '*';
}

.ant-form-item-explain {
  font-size: @secondary-font-size;
}

.ant-tabs-nav-operations {
  display: none !important;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}

.ant-picker {
  height: @large-item-height;
  width: 100%;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  text-align: left;
  padding: 12px !important;
}

.ant-picker-cell {
  color: @input-border-color !important;

  .ant-checkbox-wrapper {
    color: @input-border-color !important;
  }
}

.ant-picker-cell-in-view {
  .ant-picker-calendar-date-value {
    color: @title-color !important;
  }

  .ant-checkbox-wrapper {
    color: @text-color !important;
  }
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  overflow: hidden;
  height: auto !important;
  padding-bottom: @base-margin;
  display: flex;
  flex-direction: column;
}

.ant-picker-calendar-date {
  border-color: @secondary-text-color !important;
  background: white !important;
}

.ant-picker-calendar-date-value {
  font-weight: bold;
  text-align: left;
  margin-top: calc(@base-margin / 4);
  margin-bottom: calc(@base-margin / 4);
}

.ant-collapse {
  border-radius: @common-radius;
}

.ant-alert-description {
  text-align: justify;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: @primary-font-size;
}

.ant-descriptions-item-label {
  word-break: normal;
}

.ant-descriptions-item-content {
  word-break: normal;
}

/* CUSTOM */

.layout-header-wrapper {
  display: flex;
  max-width: 1300px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
}

.empty-header {
  justify-content: left;
  margin-bottom: calc(@base-margin * 4)
}

.view-header-one-row-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 620px) {
    flex-direction: column;
  }
}

.view-header-one-row-control-wrapper {
  display: flex;
  align-items: center;

  @media (max-width: 620px) {
    margin-bottom: @base-margin;
  }
}

.view-header-two-rows-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  @media (max-width: 420px) {
    flex-direction: column;
    margin-bottom: @base-margin;
  }
}

.view-header-two-rows-2 {
  display: flex;
  align-items: center;
  margin-bottom: @base-margin;
}

.header-icon {
  font-size: calc(@header-height / 2);
  margin-top: calc(@header-height / 4);
  cursor: pointer;
}

.header-logo {
  height: 100%;
  padding: calc(@base-margin / 2);
  display: block
}

.header-search-input {
  height: @large-item-height;
  font-size: @primary-font-size;
}

.header-item {
  margin-left: @base-margin !important;

  .ant-input {
    height: @large-item-height;
  }

  .ant-input-number {
    height: @large-item-height;
    line-height: @large-item-height;
  }
}

.large-items-container {
  display: flex;
  flex-direction: row;

  .ant-input-number-input {
    height: @large-item-height;
    line-height: @large-item-height;
  }
}

.header-item-small {
  width: 120px;
}

.header-item-medium {
  width: 200px;
}

.content-wrapper {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1300px) {
    padding-left: @base-margin;
    padding-right: @base-margin;
  }
}

.boarder-tab-content-wrapper {
  max-width: 570px;
  margin: 0 auto;
}

.dormitory-tab-content-wrapper {
  max-width: 698px;
  margin: 0 auto;
}

.sub-actions-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: @base-margin;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.vertical-base-margin {
  margin-top: @base-margin;
  margin-bottom: @base-margin;
}

.red {
  color: red !important;
}

.green {
  color: green !important;
}

.action-icon {
  transition: color @common-animation;
  font-size: 24px;
  color: @text-color !important;

  &:hover {
    color: @primary-color !important;
  }
}

.action-icon-disabled {
  font-size: 24px;
  color: @secondary-text-color !important;
  cursor: default !important;
}

.calendar-month-switcher-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  margin-top: @base-margin;
  margin-bottom: @base-margin;
  font-weight: bold;
  text-transform: capitalize;
  color: @title-color;
}

.center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.header-margin {
  margin-top: @header-height;
}

.body-align-top {
  .ant-picker-panel {
    .ant-picker-date-panel {
      .ant-picker-body {
        .ant-picker-content {
          tbody {
            vertical-align: top;
          }
        }
      }
    }
  }
}
@primary-color: #e5b002;